import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

function Slider() {
  const history = useHistory();

  const tryItNowHandler = () => {
    history.push("/local-try");
  };
  return (
    <>
      <div
        className="banner-one"
        style={{
          backgroundImage: "url(images/main-slider/slider1/pic2.png)",
        }}
      >
        <div className="container">
          <div className="banner-inner">
            <div className="img1">
              <img src="images/main-slider/slider1/pic3.png" alt="" />
            </div>
            <div className="img2">
              <img src="images/main-slider/slider1/pic4.png" alt="" />
            </div>
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="banner-content">
                  <h6
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                    className="wow fadeInUp sub-title text-primary"
                  >
                    We Help You
                  </h6>
                  <h1
                    data-wow-duration="1.2s"
                    data-wow-delay="1s"
                    className="wow fadeInUp "
                  >
                    Learn a New <span className="text-primary">Language</span>{" "}
                  </h1>
                  <p
                    data-wow-duration="1.4s"
                    data-wow-delay="1.5s"
                    className="wow fadeInUp m-b30"
                  >
                    Language Learning Assistant helps you to learn a new
                    language through movies, songs, and text articles.
                  </p>
                  <div className="extra-cell">
                    <button
                      name="submit"
                      type="submit"
                      value="Submit"
                      className="btn btn-primary rounded-md"
                      onClick={tryItNowHandler}
                    >
                      Try it Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <video width="700" height="500" autoPlay muted>
                    <source
                      src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Slider;
