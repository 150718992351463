import Icons from "../../public/images/lla/sprite.svg";

type IconProps = {
  className: string;
  name: string;
  color?: string;
  size?: number;
};
//todo: use this!
//import PropTypes from 'prop-types';
// Icon.propTypes = {
//   name: PropTypes.string.isRequired,
//   color: PropTypes.string,
//   size: PropTypes.number,
// };
const Icon = (props: IconProps) => (
  <svg
    className={props.className}
    fill={props.color}
    width={props.size}
    height={props.size}
  >
    <use xlinkHref={`${Icons}#icon-${props.name}`} />
  </svg>
);

export default Icon;
