export enum PractiseMode {
  SPEAKING = "SPEAKING",
  READING = "READING",
  LISTENING = "LISTENING",
  ARTICLE = "ARTICLE",
}

export type SpeakingConfiguration = {
  /* positive to get faster, negative to get slower */
  speedTimes: number;
  repeatSpeedPercent: number;
  repeatition: number;
};

export type Configuration = {
  activePracticeMode: PractiseMode;
  speakingConfiguration: SpeakingConfiguration;
};
