import FileUploader from "../file-uploader";

type UploadMediaFileStepProps = {
  mediaHandler: (files: FileList | null) => void;
};

const UploadMediaFileStep = ({ mediaHandler }: UploadMediaFileStepProps) => {
  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-8">
          <div className="banner-content">
            <h6
              data-wow-duration="1s"
              data-wow-delay="0.5s"
              className="wow fadeInUp sub-title text-primary"
            ></h6>
            <h2
              data-wow-duration="1.2s"
              data-wow-delay="1s"
              className="wow text-primary "
            >
              {"Step 1: "}
              <span className="fadeInUp" style={{ color: "black" }}>
                Upload Your Video or Audio
              </span>{" "}
            </h2>
            <p
              data-wow-duration="1.4s"
              data-wow-delay="1.5s"
              className="wow fadeInUp m-b30"
            >
              You can upload a video or an audio file from your local computer
              to learn with. This file is used localy, and does not be uploaded
              on our server through the Internet to keep your privacy.
            </p>
            <FileUploader onFileSelect={mediaHandler} />
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadMediaFileStep;
