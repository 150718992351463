import React from "react";
import { useState } from "react";
import { Subtitle, SubtitleType, DesibleChunk } from "../../lla/core/subtitle";
import Engine from "../components/engine";
import UploadMediaFileStep from "../components/local-try/upload-media-file-step";
import UploadSubtitleFileStep from "../components/local-try/upload-subtitle-file-step";
import "../css/engine.css";

enum Step {
  UPLOAD_MEDIA = "UPLOAD_MEDIA",
  ULOAD_SUBTITLE = "ULOAD_SUBTITLE",
  READY_TO_START = "READY_TO_START",
}

const LocalTry = () => {
  const [step, setStep] = useState<Step>(Step.UPLOAD_MEDIA);
  const [mediaContentUrl, setMediaContentUrl] = useState<string>();
  const [mediaFile, setMediaFile] = useState<File>();
  const [subtitle, setSubtitle] = useState<Subtitle>();

  const [audioContext, setAudioContext] = useState<AudioContext>();

  const [videoRef, setvideoRef] = useState(React.createRef<HTMLVideoElement>());

  const mediaUploadHandler = (files: FileList | null) => {
    setStep(Step.ULOAD_SUBTITLE);
    setMediaFile(files![0]);
    /* it is not allowd to build in the first */
    setAudioContext(new AudioContext());
    setMediaContentUrl(URL.createObjectURL(files![0]!));
    console.log(files);
  };

  const subtitleUploadHandler = (files: FileList | null) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      setSubtitle(
        new Subtitle(SubtitleType.SRT, e.target!.result!.toString(), undefined)
      );
      setStep(Step.READY_TO_START);
      console.log(subtitle);
      //TODO: handle the subtitle format!
      // setSubtitle(e.target.result, file.name.split(".").pop());
    };

    reader.onerror = function (stuff) {
      console.log("error", stuff);
    };
    reader.readAsText(files![0]);
  };

  const skipSubtitleUploadHandler = async () => {
    await generateSubtitleTimeline(mediaFile!);
    setStep(Step.READY_TO_START);
  };

  const onChangeMedia = () => {
    setStep(Step.UPLOAD_MEDIA);
  };

  const generateSubtitleTimeline = async (mediaFile: File) => {
    var arrayBuffer = await mediaFile.arrayBuffer();
    const audioBuffer = await audioContext!.decodeAudioData(arrayBuffer);

    var offlineAudioContext = new OfflineAudioContext(
      audioBuffer.numberOfChannels,
      audioBuffer.length,
      audioBuffer.sampleRate
    );

    var bufferSource = offlineAudioContext.createBufferSource();
    bufferSource.buffer = audioBuffer;

    await offlineAudioContext.audioWorklet.addModule(
      "worklet/media-analyser.js"
    );

    console.log("data.byteLength");

    let decibleDetectorNode = new AudioWorkletNode(
      offlineAudioContext,
      "decible-analyser",
      {
        processorOptions: {
          duration: audioBuffer.duration,
          length: audioBuffer.length,
        },
      }
    );

    var records: DesibleChunk[] = [];
    decibleDetectorNode.port.onmessage = function (event) {
      records.push(event.data);
      if (event.data.lastRecord) {
        setSubtitle(new Subtitle(SubtitleType.AUTO, undefined, records));
      }
    };
    bufferSource.connect(decibleDetectorNode);
    bufferSource.start(0);
    offlineAudioContext.startRendering();
  };

  return (
    <>
      <div
        className="lla-container"
        style={{
          backgroundImage: "url(images/main-slider/slider1/pic2.png)",
        }}
      >
        {step === Step.UPLOAD_MEDIA && (
          <UploadMediaFileStep mediaHandler={mediaUploadHandler} />
        )}
        {step === Step.ULOAD_SUBTITLE && (
          <UploadSubtitleFileStep
            mediaHandler={subtitleUploadHandler}
            cancleHandler={skipSubtitleUploadHandler}
          />
        )}
        {step === Step.READY_TO_START && (
          <div className="video-container">
            <video
              className="video-container__player"
              ref={videoRef}
              muted
              controls
            >
              <source src={mediaContentUrl} type="video/mp4" />
            </video>
            <Engine
              subtitle={subtitle}
              videoRef={videoRef}
              onChangeMedia={onChangeMedia}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default LocalTry;
