import { Switch, Route, Link } from "react-router-dom";
import Home from "./lla/pages/home";
import LocalTry from "./lla/pages/local-try";

function App() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/local-try">
        <LocalTry />
      </Route>
    </Switch>
  );
}

export default App;
