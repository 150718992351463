import React, { ChangeEvent, useRef, useState } from "react";

const FileUploader = ({ onFileSelect }) => {
  const [files, setFiles] = useState([]);

  const onChange = (e) => {
    var files = e.target.files;
    var filesArr = Array.prototype.slice.call(files);
    setFiles({ files: [...files, ...filesArr] });
    onFileSelect(files);
  };

  const removeFile = (selectedFile) => {
    setFiles(
      files.filter((x) => x !== selectedFile),
    );
  };

  return (
    <div>
      <label className="btn btn-primary rounded-md">
        <input type="file" onChange={onChange} hidden />
        Upload
      </label>
    </div>
    //https://codesandbox.io/s/xnxmzxbe?file=/index.js:119-208
  );
};

export default FileUploader;
