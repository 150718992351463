const silenceDesibleThreshold = 27;
//TODO: move them to be set by user as the configuration!
const shortIntervalInMilliToMerge = 1000;
const maxIntervalInMilli = 5000;

export type DesibleChunk = {
  db: number;
  start: number;
  end: number;
  lastRecord: boolean;
};

export class SubtitleRecord {
  duration: number;
  start: number;
  end: number;
  text: string;

  constructor(start: number, end: number, text: string) {
    this.start = start;
    this.end = end;
    this.duration = 1000 * (this.end - this.start);
    this.text = text;
  }

  getDuration(): number {
    return 1000 * (this.end - this.start);
  }
}

export enum SubtitleType
 {
  AUTO,
  VVT,
  SRT,
}

export class Subtitle {
  records: SubtitleRecord[] = [];
  type: SubtitleType;
  constructor(
    type: SubtitleType,
    content?: string,
    desibleRecords?: DesibleChunk[]
  ) {
    this.type = type;
    if (type !== SubtitleType.AUTO && content) this.parseContent(content, type);
    if (type === SubtitleType.AUTO && desibleRecords) {
      this.parseDesibles(desibleRecords);
      this.refine();
    }
  }

  private parseContent(data: string, format: SubtitleType) {
    this.records = [];
    var separator = "\n";
    var items = data.replaceAll("\r", "").split(separator + separator);
    var records: SubtitleRecord[] = [];
    items.forEach((item) => {
      var parsedRecord = item.split(separator);
      if (parsedRecord.length !== 3) return;

      let record = new SubtitleRecord(
        this.parseTime(parsedRecord[1].split("-->")[0], format),
        this.parseTime(parsedRecord[1].split("-->")[1], format),
        parsedRecord[2]
      );
      this.records.push(record);
    });
  }

  private parseTime(timeRecord: string, format: SubtitleType): number {
    var items = timeRecord.split(":");
    if (format === SubtitleType.SRT) items[2] = items[2].replace(",", ".");
    return parseFloat(
      (+items[0] * 60 * 60 + +items[1] * 60 + +items[2]).toFixed(2)
    );
  }

  getRecord(index: number): SubtitleRecord | undefined {
    if (index >= 0 && index < this.records.length) return this.records[index];
    return undefined;
  }

  private mergeSilentDesibles(desibleRecords: DesibleChunk[]) {
    let result: DesibleChunk[] = [];
    let mergedFlags = new Array(this.records.length).fill(false);
    for (var i = 0; i < desibleRecords.length; i++) {
      var j = i + 1;
      if (desibleRecords[i].db <= silenceDesibleThreshold) {
        while (
          j < desibleRecords.length &&
          desibleRecords[j].db <= silenceDesibleThreshold
        ) {
          mergedFlags[j] = true;
          j++;
        }
      }
      result.push({
        db: desibleRecords[i].db,
        start: desibleRecords[i].start,
        end: desibleRecords[j - 1].end,
        lastRecord: false,
      });
      i = j - 1;
    }
    return result;
  }

  private parseDesibles(desibleRecords: DesibleChunk[]) {
    const silentMergedRecords = this.mergeSilentDesibles(desibleRecords);
    this.records = [];

    var lastEnd = 0;
    for (var i = 0; i < silentMergedRecords.length; i++) {
      if (silentMergedRecords[i].db >= silenceDesibleThreshold) {
        var j = i + 1;
        while (
          j < silentMergedRecords.length &&
          silentMergedRecords[j].db >= silenceDesibleThreshold
        ) {
          j++;
        }

        const record = new SubtitleRecord(
          lastEnd !== 0 ? (lastEnd + silentMergedRecords[i].start) / 2 : lastEnd,
          silentMergedRecords[j].end,
          ""
        );
        this.records.push(record);
        lastEnd = record.end;
        i = j;
      }
    }
    console.log(this.records);
  }

  refine() {
    let removedFlags = new Array(this.records.length).fill(false);
    for (var i = 0; i < this.records.length - 1; i++) {
      if (
        this.records[i].getDuration() <= shortIntervalInMilliToMerge &&
        this.records[i].getDuration() + this.records[i + 1].getDuration() <=
          maxIntervalInMilli
      ) {
        removedFlags[i] = true;
        this.records[i + 1].start = this.records[i].start;
        this.records[i + 1].duration = this.records[i + 1].getDuration();
      }
    }
    this.records = this.records.filter(
      (element, index) => removedFlags[index] === false
    );
    console.log(this.records);
  }
}
