import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Configuration,
  PractiseMode,
  SpeakingConfiguration,
} from "../core/config";
import { Subtitle } from "../core/subtitle";
import ListenRepeatEngine from "./listen-repeat-engine";
import "../css/engine.css";

import Icon from "./icon";

type EngineProps = {
  subtitle: Subtitle | undefined;
  videoRef: React.RefObject<HTMLVideoElement>;
  onChangeMedia: () => void;
};

const Engine = (props: EngineProps) => {
  //TODO: read config from server watch video!
  const [configuration, setConfiguration] = useState<Configuration>({
    activePracticeMode: PractiseMode.SPEAKING,
    speakingConfiguration: {
      speedTimes: 1,
      repeatition: 1,
      repeatSpeedPercent: 0,
    },
  });

  const onOffButtonRef = useRef<HTMLButtonElement>();
  /* to use in the onClick of ref button */
  const onOffSwitchRef = useRef<boolean>(false);

  const [onOffSwitch, setOnOffSwitch] = useState(false);

  const createSwitchButton = () => {
    let button: HTMLButtonElement = document.createElement("button");
    button.onclick = activeSwitchHandler;
    button.name = "switch-button";
    button.className = "btn btn-primary rounded-md action-icon";
    button.innerText = "On";

    let actionBox = document.createElement("div");
    actionBox.className = "control-box";
    actionBox.appendChild(button);

    props.videoRef.current?.parentElement?.appendChild(actionBox);

    onOffButtonRef.current = button;

    // setOnOffButton(button);
    // return button;
  };

  // const createControlBox = () => {
  //   createSwitchButton();
  // };

  const activeSwitchHandler = () => {
    const targetStatus = !onOffSwitchRef.current;
    
    if (targetStatus) {
      props.videoRef.current!.controls = false;
      props.videoRef.current!.muted = true;
      onOffButtonRef.current!.innerText = "Off";
    } else {
      props.videoRef.current!.controls = true;
      props.videoRef.current!.muted = false;
      onOffButtonRef.current!.innerText = "On";
    }
    props.videoRef.current!.pause();
    props.videoRef.current!.currentTime = 0;
    setOnOffSwitch(targetStatus);
    onOffSwitchRef.current = targetStatus;
  };

  const changeMediaHandler = () => {
    props.onChangeMedia();
  };

  const speakingConfigurationSaveHandler = (
    speakingConfiguration: SpeakingConfiguration
  ) => {};

  // useEffect(() => {
  //   createControlBox();
  // }, []);

  return (
    <>
      {/* <button
        name="try-another-video"
        className="btn btn-primary rounded-md"
        onClick={changeMediaHandler}
      >
        Try another video
      </button> */}
      {/* {onOffSwitch === true && ( */}



      {/* <div className="video-container__engine">
        <div className="video-container__engine__setting-box">
          <button className="video-container__engine__setting-box__button">
            <Icon
              name={"play2"}
              color={""}
              size={100}
              className="video-container__engine__setting-box__icon"
            />
          </button>
        </div>
      </div> */}

      {configuration.activePracticeMode === PractiseMode.SPEAKING && (
        <ListenRepeatEngine
          configuration={{
            speedTimes: 1,
            repeatSpeedPercent: 0,
            repeatition: 1,
          }}
          subtitle={props.subtitle}
          videoRef={props.videoRef}
          onChangeConfig={speakingConfigurationSaveHandler}
        />
      )}
    </>
  );
};

export default Engine;
