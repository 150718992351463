import React, { useRef } from "react";
import { useState } from "react";
import { SpeakingConfiguration } from "../core/config";
import { Subtitle, SubtitleType } from "../core/subtitle";
import "../css/engine.css";
import Icon from "./icon";

enum ActionType {
  PLAY = "PLAY",
  PAUSE = "PAUSE",
}

type Action = {
  type: ActionType;
  trigeredTime: number;
  trigeredTimeout: NodeJS.Timeout;
  performTime: number;
};


type CurrentChunk = {
  id: number;
  repeatedCount: number;
  freezedTime: number | undefined;
};

type ListenRepeatEngineProps = {
  configuration: SpeakingConfiguration;
  subtitle: Subtitle | undefined;
  videoRef: React.RefObject<HTMLVideoElement>;
  onChangeConfig: (configuration: SpeakingConfiguration) => void;
};

enum PlanStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  PAUSED = "PAUSED",
}

enum OverlayStatus {
  ON = "ON",
  OFF = "OFF",
}

const ListenRepeatEngine = (props: ListenRepeatEngineProps) => {
  const [planStatus, setPlanStatus] = useState(PlanStatus.NOT_STARTED);
  const [overlayStatus, setOverlayStatus] = useState(OverlayStatus.ON);

  const [nextAction, setNextAction] = useState<Action>();

  const [configuration, setConfiguration] = useState<SpeakingConfiguration>(
    props.configuration
  );

  const [currentChunk, setCurrentChunk] = useState<CurrentChunk>({
    id: 0,
    repeatedCount: 0,
    freezedTime: undefined,
  });

  const [overlayRef, setOverlayRef] = useState(
    React.createRef<HTMLDivElement>()
  );

  const actionFromInMilliSec = (type: ActionType, inMilliSec: number) => {
    const nowTime = new Date().getTime();
    return {
      type: type,
      trigeredTime: nowTime,
      trigeredTimeout: setTimeout(actionHandler, inMilliSec),
      performTime: nowTime + inMilliSec,
    };
  };

  /* to access uopdate state in the timeout callback */
  const nextActionRef = useRef<Action>();
  nextActionRef.current = nextAction;

  const currentChunkRef = useRef<CurrentChunk>();
  currentChunkRef.current = currentChunk;

  const startPlanHandler = () => {
    const startDelay = 500;
    setNextAction(actionFromInMilliSec(ActionType.PLAY, startDelay));
    setPlanStatus(PlanStatus.IN_PROGRESS);
  };

  const listeningPart = () => {
    return nextAction?.type === ActionType.PAUSE;
  };

  const repeatingPart = () => {
    return nextAction?.type === ActionType.PLAY;
  };

  const opositeAction = (action: ActionType) => {
    return action === ActionType.PLAY ? ActionType.PAUSE : ActionType.PLAY;
  };

  const setOverlayText = (text: string) => {
    overlayRef.current!.innerText = text;
  };

  const pausePlanHandler = () => {
    const nowTime = new Date().getTime();

    clearTimeout(nextAction?.trigeredTimeout);
    props.videoRef.current?.pause();
    setPlanStatus(PlanStatus.PAUSED);
    const updatedRepeatedCount = repeatingPart()
      ? currentChunk.repeatedCount - 1
      : currentChunk.repeatedCount;
    setCurrentChunk({
      ...currentChunk,
      freezedTime: nowTime,
      repeatedCount: updatedRepeatedCount,
    });
  };

  const resumePlanHandler = () => {
    const resumeDelay = 100;
    setNextAction(actionFromInMilliSec(ActionType.PLAY, resumeDelay));
    setPlanStatus(PlanStatus.IN_PROGRESS);
  };

  const onOverlayHandler = () => {
    setOverlayStatus(OverlayStatus.ON);
  };

  const offOverlayHandler = () => {
    setOverlayStatus(OverlayStatus.OFF);
  };

  const nextRepeatitionHandler = (chunk: CurrentChunk) => {
    setCurrentChunk({ ...chunk, repeatedCount: chunk.repeatedCount + 1 });
  };

  const nextChunkHandler = (chunk: CurrentChunk) => {
    setCurrentChunk({ ...chunk, id: chunk.id + 1, repeatedCount: 0 });
  };

  const actionHandler = () => {
    const action = nextActionRef.current;
    const chunk = currentChunkRef.current;

    let record = props.subtitle?.getRecord(chunk?.id ?? -1);
    if (record === undefined) {
      setCurrentChunk({ id: 0, repeatedCount: 0, freezedTime: undefined });
      props.videoRef.current?.pause();
      return;
    }

    if (overlayRef !== null && overlayRef.current !== null)
      setOverlayText(record.text);

    console.log(
      "Calling %s recordId:%d, getDuration(): %f, repetition:%d",
      action?.type,
      chunk?.id,
      record.getDuration(),
      chunk?.repeatedCount
    );
    const nowTime = new Date().getTime();

    if (action?.type === ActionType.PLAY) {
      props.videoRef.current!.muted = false;
      props.videoRef.current!.currentTime = record.start;
      props.videoRef.current?.play();
      // setNextAction(
      //   actionFromInMilliSec(ActionType.PAUSE, record.getDuration())
      // );
    }

    if (action?.type === ActionType.PAUSE) {
      props.videoRef.current!.muted = true;
      props.videoRef.current!.currentTime = record.start;
      /* we don't pause just make it mute to able to see the movie without sound */

      /* we check the repetition counter at the end, so minus one is a must */
      if (chunk!.repeatedCount < configuration.repeatition - 1) {
        nextRepeatitionHandler(chunk!);
      } else {
        nextChunkHandler(chunk!);
      }
    }

    setNextAction(
      actionFromInMilliSec(opositeAction(action?.type!), record.getDuration())
    );
  };

  return (
    <>
      {/* <div className="switch-toggle">
        <input type="checkbox" className="switch-toggle__checkbox" id="toggle">
          <label htmlFor="toggle" className="switch-toggle__button">
            <span className="switch-toggle__icon">&nbsp;</span>
          </label>
        </input>
      </div> */}

      <div className="video-container__engine">
        <div className="video-container__engine__control-box">
          {planStatus === PlanStatus.NOT_STARTED && (
            <button
              className="video-container__engine__control-box__button"
              onClick={startPlanHandler}
            >
              <Icon
                name={"play2"}
                color={""}
                size={100}
                className="video-container__engine__control-box__icon"
              />
            </button>
          )}
          {planStatus === PlanStatus.IN_PROGRESS && (
            <button
              name="pause-plan"
              className="video-container__engine__control-box__button"
              onClick={pausePlanHandler}
            >
              <Icon
                name={"pause"}
                color={""}
                size={100}
                className="video-container__engine__control-box__icon"
              />
            </button>
          )}
          {props.subtitle !== undefined && planStatus === PlanStatus.PAUSED && (
            <button
              name="resume-plan"
              className="video-container__engine__control-box__button"
              onClick={resumePlanHandler}
            >
              <Icon
                name={"play2"}
                color={""}
                size={100}
                className="video-container__engine__control-box__icon"
              />
            </button>
          )}

          {(props.subtitle?.type !== SubtitleType.AUTO && overlayStatus) ===
            OverlayStatus.ON && (
            <button
              name="off-overlay"
              className="video-container__engine__control-box__button"
              onClick={offOverlayHandler}
            >
              <Icon
                name={"subtitles_off"}
                color={""}
                size={100}
                className="video-container__engine__control-box__icon"
              />
            </button>
          )}

          {props.subtitle?.type !== SubtitleType.AUTO &&
            overlayStatus === OverlayStatus.OFF && (
              <button
                name="on-overlay"
                className="video-container__engine__control-box__button"
                onClick={onOverlayHandler}
              >
                <Icon
                  name={"subtitles"}
                  color={""}
                  size={100}
                  className="video-container__engine__control-box__icon"
                />
              </button>
            )}
        </div>
        {overlayStatus === OverlayStatus.ON && (
          <div
            ref={overlayRef}
            className="video-container__engine__overlay"
          ></div>
        )}
      </div>
    </>
  );
};

export default ListenRepeatEngine;
